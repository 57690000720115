/* @charset "utf-8"; */
/* CSS Document */

// SAKINA - START

/* THEME - START */

$THEME_PRIMARY_DARK_COLOR: #004E94
$THEME_PRIMARY_DARK_RED: 0
$THEME_PRIMARY_DARK_GREEN: 78
$THEME_PRIMARY_DARK_BLUE: 148
$THEME_PRIMARY_NORMAL_COLOR: #005DA3
$THEME_PRIMARY_NORMAL_RED: 0
$THEME_PRIMARY_NORMAL_GREEN: 93
$THEME_PRIMARY_NORMAL_BLUE: 163
$THEME_PRIMARY_LIGHT_COLOR: #1476BC
$THEME_PRIMARY_LIGHT_RED: 20
$THEME_PRIMARY_LIGHT_GREEN: 118
$THEME_PRIMARY_LIGHT_BLUE: 188
$THEME_PRIMARY_SOFT_COLOR: #2876BC
$THEME_PRIMARY_SOFT_RED: 40
$THEME_PRIMARY_SOFT_GREEN: 118
$THEME_PRIMARY_SOFT_BLUE: 188
$THEME_PRIMARY_ACCENT_COLOR: #29ABE2
$THEME_PRIMARY_ACCENT_RED: 41
$THEME_PRIMARY_ACCENT_GREEN: 171
$THEME_PRIMARY_ACCENT_BLUE: 226
$THEME_PRIMARY_HIGHLIGHT_COLOR: #6BB9FF
$THEME_PRIMARY_HIGHLIGHT_RED: 107
$THEME_PRIMARY_HIGHLIGHT_GREEN: 185
$THEME_PRIMARY_HIGHLIGHT_BLUE: 255

$THEME_SECONDARY_DARK_COLOR: #F26F21
$THEME_SECONDARY_DARK_RED: 242
$THEME_SECONDARY_DARK_GREEN: 111
$THEME_SECONDARY_DARK_BLUE: 33
$THEME_SECONDARY_NORMAL_COLOR: #F7931E
$THEME_SECONDARY_NORMAL_RED: 247
$THEME_SECONDARY_NORMAL_GREEN: 147
$THEME_SECONDARY_NORMAL_BLUE: 30

$THEME_TERTIARY_DARK_COLOR: #787878
$THEME_TERTIARY_DARK_RED: 120
$THEME_TERTIARY_DARK_GREEN: 120
$THEME_TERTIARY_DARK_BLUE: 120
$THEME_TERTIARY_NORMAL_COLOR: #B4B4B4
$THEME_TERTIARY_NORMAL_RED: 180
$THEME_TERTIARY_NORMAL_GREEN: 180
$THEME_TERTIARY_NORMAL_BLUE: 180
$THEME_TERTIARY_LIGHT_COLOR: #E6E6E6
$THEME_TERTIARY_LIGHT_RED: 230
$THEME_TERTIARY_LIGHT_GREEN: 230
$THEME_TERTIARY_LIGHT_BLUE: 230
$THEME_TERTIARY_SOFT_COLOR: #FFFFFF
$THEME_TERTIARY_SOFT_RED: 255
$THEME_TERTIARY_SOFT_GREEN: 255
$THEME_TERTIARY_SOFT_BLUE: 255

$THEME_QUATERNARY_LIGHT_COLOR: #efefef
$THEME_QUATERNARY_DARK_COLOR: #666666
$THEME_QUARTERNARY_SOFT_COLOR: #e9efef

$THEME_TERTIARY_SOFT_BLUE_COLOR: #1576B3
$THEME_TERTIARY_DARK_BLUE_COLOR: #064F89
$THEME_TERTIARY_SOFT_GREY_COLOR: #999090
$THEME_TERTIARY_SOFT_ORANGE_COLOR: #F38F1E
$THEME_TERTIARY_DARK_ORANGE_COLOR: #D85526
$THEME_TERTIARY_SKY_BLUE_COLOR: #91B9CA
$THEME_TERTIARY_SEMI_WHITE_COLOR: #F2F2F2

$THEME_BRIINSURANCE_BLUE_COLOR: #0045E6
$THEME_BRIINSURANCE_ORANGE_COLOR: #FF4D00

/* THEME - END */

/* INDICATOR - START */

$THEME_INDICATOR_SUCCESS_DARK_COLOR: #009245
$THEME_INDICATOR_SUCCESS_DARK_RED: 0
$THEME_INDICATOR_SUCCESS_DARK_GREEN: 146
$THEME_INDICATOR_SUCCESS_DARK_BLUE: 69
$THEME_INDICATOR_SUCCESS_NORMAL_COLOR: #14A659
$THEME_INDICATOR_SUCCESS_NORMAL_RED: 20
$THEME_INDICATOR_SUCCESS_NORMAL_GREEN: 166
$THEME_INDICATOR_SUCCESS_NORMAL_BLUE: 89
$THEME_INDICATOR_WARNING_COLOR: #F15A24
$THEME_INDICATOR_WARNING_RED: 241
$THEME_INDICATOR_WARNING_GREEN: 90
$THEME_INDICATOR_WARNING_BLUE: 36
$THEME_INDICATOR_FAIL_DARK_COLOR: #C1272D
$THEME_INDICATOR_FAIL_DARK_RED: 193
$THEME_INDICATOR_FAIL_DARK_GREEN: 36
$THEME_INDICATOR_FAIL_DARK_BLUE: 45
$THEME_INDICATOR_FAIL_NORMAL_COLOR: #D53841
$THEME_INDICATOR_FAIL_NORMAL_RED: 213
$THEME_INDICATOR_FAIL_NORMAL_GREEN: 56
$THEME_INDICATOR_FAIL_NORMAL_BLUE: 65
$THEME_INDICATOR_INFORMATION_COLOR: #0071BC
$THEME_INDICATOR_INFORMATION_RED: 0
$THEME_INDICATOR_INFORMATION_GREEN: 113
$THEME_INDICATOR_INFORMATION_BLUE: 188
$THEME_INDICATOR_SEVERITY_URGENT_COLOR: #FF0000
$THEME_INDICATOR_SEVERITY_URGENT_RED: 255
$THEME_INDICATOR_SEVERITY_URGENT_GREEN: 0
$THEME_INDICATOR_SEVERITY_URGENT_BLUE: 0
$THEME_INDICATOR_SEVERITY_HIGH_COLOR: #C92D00
$THEME_INDICATOR_SEVERITY_HIGH_RED: 201
$THEME_INDICATOR_SEVERITY_HIGH_GREEN: 45
$THEME_INDICATOR_SEVERITY_HIGH_BLUE: 0
$THEME_INDICATOR_SEVERITY_MEDIUM_COLOR: #F7941D
$THEME_INDICATOR_SEVERITY_MEDIUM_RED: 247
$THEME_INDICATOR_SEVERITY_MEDIUM_GREEN: 148
$THEME_INDICATOR_SEVERITY_MEDIUM_BLUE: 29
$THEME_INDICATOR_SEVERITY_LOW_COLOR: #D8CB30
$THEME_INDICATOR_SEVERITY_LOW_RED: 216
$THEME_INDICATOR_SEVERITY_LOW_GREEN: 203
$THEME_INDICATOR_SEVERITY_LOW_BLUE: 48

/* INDICATOR - END */

/* LIST - START */

$THEME_LIST_EVEN_COLOR: #FFFFFF
$THEME_LIST_EVEN_RED: 255
$THEME_LIST_EVEN_GREEN: 255
$THEME_LIST_EVEN_BLUE: 255
$THEME_LIST_EVEN_OPACITY: 0.2
$THEME_LIST_ODD_COLOR: #FFFFFF
$THEME_LIST_ODD_RED: 255
$THEME_LIST_ODD_GREEN: 255
$THEME_LIST_ODD_BLUE: 255
$THEME_LIST_ODD_OPACITY: 0.1

/* LIST - END */

/* CONVERSATION - START */

$THEME_CONVERSATION_CUSTOMER_COLOR: #E0BB16
$THEME_CONVERSATION_CUSTOMER_RED: 224
$THEME_CONVERSATION_CUSTOMER_GREEN: 187
$THEME_CONVERSATION_CUSTOMER_BLUE: 22
$THEME_CONVERSATION_DIVISION_COLOR: #943707
$THEME_CONVERSATION_DIVISION_RED: 148
$THEME_CONVERSATION_DIVISION_GREEN: 55
$THEME_CONVERSATION_DIVISION_BLUE: 7
$THEME_CONVERSATION_PRIVATE_COLOR: #F7941D
$THEME_CONVERSATION_PRIVATE_RED: 247
$THEME_CONVERSATION_PRIVATE_GREEN: 148
$THEME_CONVERSATION_PRIVATE_BLUE: 29
$THEME_CONVERSATION_PUBLIC_COLOR: #1C75BC
$THEME_CONVERSATION_PUBLIC_RED: 28
$THEME_CONVERSATION_PUBLIC_GREEN: 117
$THEME_CONVERSATION_PUBLIC_BLUE: 188
$THEME_CONVERSATION_DISABLED: #6D6E71
$THEME_CONVERSATION_DISABLED_RED: 109
$THEME_CONVERSATION_DISABLED_GREEN: 110
$THEME_CONVERSATION_DISABLED_BLUE: 113

/* CONVERSATION - START */


/* RRNEWAL - START */

$THEME_POLICY_EXECUTIVE_COLOR: #d4af37
$THEME_POLICY_EXECUTIVE_RED: 212
$THEME_POLICY_EXECUTIVE_GREEN: 175
$THEME_POLICY_EXECUTIVE_BLUE: 55

/* RENEWAL - START */

/* BRINS-WEBSITE - START */

$THEME_PRIMARY_DARK_BLACK: #000000
$THEME_PRIMARY_PILLAR_CORPORATE: #E81E25
$THEME_PRIMARY_PILLAR_SYARIAH: #69A127
$THEME_PRIMARY_PILLAR_MIKRO: #D56707
$THEME_PRIMARY_PILLAR_RITEL: #004980
$THEME_PRIMARY_PILLAR_DIGITAL: #C8C8C8

/* BRINS-WEBSITE - END */

// SAKINA - END

// IMPORT - START

@import form-initialization-general

// IMPORT - END


// SETUP - START

/* SEMANTIC ELEMENTS - START */

div.DivBackground
	+generateBackgroundGradientTopBottom($THEME_PRIMARY_LIGHT_COLOR, $THEME_PRIMARY_LIGHT_RED, $THEME_PRIMARY_LIGHT_GREEN, $THEME_PRIMARY_LIGHT_BLUE, $THEME_PRIMARY_DARK_COLOR, $THEME_PRIMARY_DARK_RED, $THEME_PRIMARY_DARK_GREEN, $THEME_PRIMARY_DARK_BLUE)

footer
	background-image: url($URL_SHAPE_RESOURCE + "shape_footer.svg")
	background-color: transparent
	background-position: top
	background-repeat: no-repeat
	background-size: cover

+generateInputPlaceholder($THEME_TERTIARY_NORMAL_COLOR)

/* SEMANTIC ELEMENTS - END */

/* NEUTRALIZATION - START */

h1, h2, h3, h4, h5, h6, p, mark, ol, ul, li
	background-color: transparent

/* NEUTRALIZATION - END */

// SETUP - END


// LAYOUT - START

/* GENERAL - START */

div.DivForm, div.DivFormBranch
	background-color: $THEME_TERTIARY_SOFT_COLOR
	span, p
		color: $THEME_TERTIARY_DARK_COLOR
	span.SpanActiveStatus, i.IActiveStatus
		color: $THEME_TERTIARY_SOFT_COLOR

div.DivCard
	+generateBackgroundGradientLeftRight($THEME_PRIMARY_LIGHT_COLOR, $THEME_PRIMARY_LIGHT_RED, $THEME_PRIMARY_LIGHT_GREEN, $THEME_PRIMARY_LIGHT_BLUE, $THEME_PRIMARY_DARK_COLOR, $THEME_PRIMARY_DARK_RED, $THEME_PRIMARY_DARK_GREEN, $THEME_PRIMARY_DARK_BLUE)
	span
		color: $THEME_TERTIARY_SOFT_COLOR

div.DivCardFinish
	+generateBackgroundGradientLeftRight($THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE, $THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE)
	span
		color: $THEME_TERTIARY_SOFT_COLOR

div.DivForm
	div#divPercentageApproval, div#divPercentageConfirmation
		background-color: $THEME_SECONDARY_DARK_COLOR
		div#divSubPercentageProgress
			background-color: $THEME_SECONDARY_NORMAL_COLOR

div.DivProfileGeneral
	div.DivProfileInfo
		div.DivProfileInfoDetails
			a, span
				color: $THEME_TERTIARY_SOFT_COLOR

/* GENERAL - END */

/* HOME - START */

div#divBodyHome
	background-color: transparent
	input#buttonNavigation
		background-image: url($URL_ICON_RESOURCE + "icon_navigation_show.svg")

/* HOME - END */

/* DASHBOARD - START */

div#divBodyHome
	input#buttonNavigation
		background-image: url($URL_ICON_RESOURCE + "icon_navigation_show.svg")

form div.DivForm fieldset, div.DivForm
	div.DivFormHorizontalContainer, div.DivFormVerticalContainer, div.DivFormListHorizontalContainer
		div.DivListChart
			ul
				background: rgba(0,0,0,.05)
				li
					color: $THEME_TERTIARY_DARK_COLOR
					span:first-of-type
						background-color: $THEME_SECONDARY_DARK_COLOR
						color: $THEME_TERTIARY_SOFT_COLOR

div#divBodyDashboard1
	width:100%
	height:100vh
	background-repeat: no-repeat
	object-fit: cover
	// background-image: url($URL_BACKGROUND_RESOURCE + "background_home.svg")

div#divBodyDashboardBussinesPillars
	div.DivContentLayoutContent
		div.DivContentCorporatePageImage
			h1.headerPillarCorporate
				color: $THEME_PRIMARY_PILLAR_CORPORATE
			h1.headerPillarRitel
				color: $THEME_PRIMARY_PILLAR_RITEL
			h1.headerPillarMikro
				color: $THEME_PRIMARY_PILLAR_MIKRO
			h1.headerPillarSyariah
				color: $THEME_PRIMARY_PILLAR_SYARIAH


/* DASHBOARD - END */

/* TABLE - START */

div.DivTableContainer
	ul.ListTableThumbnails, ul.ListTableTicket
		background-color: rgba($THEME_TERTIARY_SOFT_RED, $THEME_TERTIARY_SOFT_GREEN, $THEME_TERTIARY_SOFT_BLUE, 0.1)
	ul.ListTableConversation
		li
			background-color: rgba($THEME_TERTIARY_SOFT_RED, $THEME_TERTIARY_SOFT_GREEN, $THEME_TERTIARY_SOFT_BLUE, 0.2)
	ul.ListTableTicket
		li:nth-child(odd)
			background-color: rgba($THEME_TERTIARY_SOFT_RED, $THEME_TERTIARY_SOFT_GREEN, $THEME_TERTIARY_SOFT_BLUE, 0.2)
		span.TicketLow
			background-color: green
		span.TicketMedium
			background-color: orange
		span.TicketHigh
			background-color: red
	table
		thead
			tr
				th
					background-color: $THEME_TERTIARY_SOFT_COLOR
		tbody
			tr:nth-child(even)
				td
					background-color: rgba($THEME_TERTIARY_SOFT_RED, $THEME_TERTIARY_SOFT_GREEN, $THEME_TERTIARY_SOFT_BLUE, 0.1)
			tr:nth-child(odd)
				td
					background-color: rgba($THEME_TERTIARY_SOFT_RED, $THEME_TERTIARY_SOFT_GREEN, $THEME_TERTIARY_SOFT_BLUE, 0.2)


form.FormInsertConversation, form.FormUploadFile
	table.TableListDetails
		color: $THEME_TERTIARY_DARK_COLOR


/* TABLE - END */

/* LOADING - START */

div#divCurtainLoading, div#divModal
	background-color: rgba(0, 0, 0, 0.6)
	div#divLoadingBar
		+generateBackgroundGradientLeftRight($THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE, $THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE)
	span
		color: $THEME_TERTIARY_SOFT_COLOR
div#divModal
	span
		color: $THEME_TERTIARY_DARK_COLOR

/* LOADING - END */

/* NOTIFICATION - START */

div#divNotificationInstall, div#divNotificationInstallList
	+generateBackgroundGradientLeftRight($THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE, $THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE)
	color: $THEME_TERTIARY_SOFT_COLOR
	input[type=button]
		color: $THEME_TERTIARY_SOFT_COLOR
		+generateBackgroundGradientLeftRight($THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE, $THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE)
	input[type=button]#buttonNotificationClose
		background-image: url($URL_ICON_RESOURCE + "icon_X.svg")

/* NOTIFICATION - END */

// LAYOUT - END


// LABEL - START

/* GENERAL - START */

form
	div.DivForm
		h3
			color: $THEME_SECONDARY_DARK_COLOR
		fieldset
			legend
				color: $THEME_SECONDARY_NORMAL_COLOR
			label
				color: $THEME_TERTIARY_DARK_COLOR
	label.LabelAttachment
		+generateBackgroundGradientLeftRight($THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE, $THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE)
	label.LabelAttachmentDisable
		background: $THEME_CONVERSATION_DISABLED

div.DivBodyGeneral, div.DivVerifierGeneral, div.DivCenterGeneral
	header.HeaderGeneral
		display: flex
		flex-direction: column
		align-content: center
		align-items: center
		justify-content: center
		background-image: url($URL_PHOTO_RESOURCE + "background/photo_signature.png")
		background-size: cover
		height: 400px
		h2, p
			margin: 0
			color: $THEME_TERTIARY_SOFT_COLOR
	div.DivForm
		h3, h4
			color: $THEME_SECONDARY_DARK_COLOR
		label, .legend-title
			color: $THEME_TERTIARY_DARK_COLOR
	div.DivCenterContainer
		div.DivForm
			div.DivSubHeaderHeadline
				label
					color: $THEME_SECONDARY_NORMAL_COLOR
				h2
					color: $THEME_SECONDARY_DARK_COLOR
			div.DivHeaderStatus
				span
					color: $THEME_TERTIARY_SOFT_COLOR
				span.SpanSelectStatus
					background-color: $THEME_SECONDARY_DARK_COLOR
				span.Urgent
					background-color: $THEME_INDICATOR_SEVERITY_URGENT_COLOR
				span.SeverityHigh
					background-color: $THEME_INDICATOR_SEVERITY_HIGH_COLOR
				span.SeverityMedium
					background-color: $THEME_INDICATOR_SEVERITY_MEDIUM_COLOR
				span.SeverityLow
					background-color: $THEME_INDICATOR_SEVERITY_LOW_COLOR
			div.DivFormHorizontalContainer
				div.DivHeaderInfo
					dl
						dt, dd
							color: $THEME_TERTIARY_DARK_COLOR
				div.DivHeaderList
					ul
						li
							background-color: $THEME_TERTIARY_LIGHT_COLOR
							span
								color: $THEME_TERTIARY_DARK_COLOR

div.DivForm, form div.DivForm fieldset
	textarea#spanIncidentNote
		background-color: white
	textarea.TextareaDisable
		background-color: $THEME_TERTIARY_SOFT_COLOR


/* GENERAL - END */

/* NAVIGATION - START */

nav
	color: $THEME_TERTIARY_SOFT_COLOR
	figure
		div, img#imageProfilePicture, span#spanProfileNameInitial
			border-color: $THEME_TERTIARY_SOFT_COLOR
		div
			background-color: transparent
			img#imageProfilePicture
				background-color: transparent
			span#spanProfileNameInitial
				background-color: $THEME_PRIMARY_HIGHLIGHT_COLOR
	div
		a
			color: $THEME_PRIMARY_DARK_BLACK

/* NAVIGATION - END */

/* SIGN IN - START */

div#divBodySignIn
	header#headerSignIn
		h1, span
			color: $THEME_SECONDARY_DARK_COLOR
	div.DivEmailNotRegistered
		p.PEmailNotRegistered, a.AContactUs, p.PVersion
			color: $THEME_TERTIARY_SOFT_COLOR

div.DivSignInContainer, div.DivSignUpContainer
	div.DivSignInBackground, div.DivSignUpBackground
		background-image: url($URL_PHOTO_RESOURCE + "background/photo_signin.png")
	div.DivSignInFormContainer, div.DivSignUpFormContainer
		form
			fieldset
				div.DivInputHorizontalContainer
					input[type=email]
						background-image: url($URL_ICON_RESOURCE + "icon_mail.svg")
					div.DivEmailIcon
						background-image: url($URL_ICON_RESOURCE + "icon_mail.svg")
					div.DivPerson
						background-image: url($URL_ICON_RESOURCE + "icon_id.svg")
					div.DivLocation
						background-image: url($URL_ICON_RESOURCE + "icon_location_gray.svg")
					div.DivKtp
						background-image: url($URL_ICON_RESOURCE + "icon_ktp.svg")
					div.DivPhone
						background-image: url($URL_ICON_RESOURCE + "icon_call.svg")
					div.DivZipCode
						background-image: url($URL_ICON_RESOURCE + "icon_zipcode.svg")
					div.DivBranch
						background-image: url($URL_ICON_RESOURCE + "icon_branch.svg")
					div.DivMoney
						background-image: url($URL_ICON_RESOURCE + "icon_money.svg")
					div.DivDocument
						background-image: url($URL_ICON_RESOURCE + "icon_document.svg")
					div.DivRelationship
						background-image: url($URL_ICON_RESOURCE + "icon_relationship.svg")

/* SIGN IN - END */

/* DASHBOARD - START */

div#divMainDashboard, div#divBodyDashboard
	div.DivHighlight
		span.SpanHighlight
			background-color: $THEME_SECONDARY_DARK_COLOR
			color: $THEME_TERTIARY_SOFT_COLOR

/* DASHBOARD - END */

/* TABLE - START */

div.DivTableContainer, div.DivListContainer
	th
		color: $THEME_TERTIARY_SOFT_GREY_COLOR
	td
		color: $THEME_PRIMARY_DARK_BLACK
	span.SpanTicketStatus
		background-color: $THEME_PRIMARY_HIGHLIGHT_COLOR
	table.TableListDetailsAttachment
		label.LabelAttachment
			+generateBackgroundGradientLeftRight($THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE, $THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE)

/* TABLE - END */

/* DYNAMIC CONTAINER - START */

div.DivListControlButton
	label
		color: $THEME_TERTIARY_SOFT_COLOR

div.DivTransparantContainer, div.DivListTransparantContainer
	background-color: rgba($THEME_TERTIARY_SOFT_RED, $THEME_TERTIARY_SOFT_GREEN, $THEME_TERTIARY_SOFT_BLUE, 0.2)

div.DivNavigationControlButton
	input[type=radio] + label
		color: $THEME_TERTIARY_SOFT_COLOR
		+generateBackgroundGradientLeftRight($THEME_PRIMARY_LIGHT_COLOR, $THEME_PRIMARY_LIGHT_RED, $THEME_PRIMARY_LIGHT_GREEN, $THEME_PRIMARY_LIGHT_BLUE, $THEME_PRIMARY_DARK_COLOR, $THEME_PRIMARY_DARK_RED, $THEME_PRIMARY_DARK_GREEN, $THEME_PRIMARY_DARK_BLUE)
	input[type=radio]:checked + label
		color: $THEME_TERTIARY_SOFT_COLOR
		+generateBackgroundGradientLeftRight($THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE, $THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE)

div.DivDynamicContainer
	form.FormInsertConversation
		div.DivConversationGeneral
			div.DivConversationContent
				div.DivAttachmentContainer
					background-color: $THEME_TERTIARY_LIGHT_COLOR

div.DivDynamicContainer
	// div.DivSubDynamicContainer
	div.DivFormListSurveyModel, div.DivFormListSurveyThirdPartyLiability
		label.LabelClaimLetterStatus, label.LabelClaimSurveyStatus
			a
				color: $THEME_TERTIARY_LIGHT_COLOR

.ProfileDivision
	background-color: $THEME_CONVERSATION_DIVISION_COLOR

.ProfileCustomer
	background-color: $THEME_CONVERSATION_CUSTOMER_COLOR

.ProfileBranch
	background-color: $THEME_INDICATOR_SUCCESS_NORMAL_COLOR

.PrivacyPrivate
	background-color: $THEME_CONVERSATION_PRIVATE_COLOR

.PrivacyPublic
	background-color: $THEME_CONVERSATION_PUBLIC_COLOR

.StatusActive
	background-color: $THEME_PRIMARY_LIGHT_COLOR

.StatusNotActive
	background-color: $THEME_INDICATOR_SEVERITY_HIGH_COLOR

.Verified
	background-color:  $THEME_INDICATOR_SUCCESS_DARK_COLOR

.NotVerified
	background-color: $THEME_INDICATOR_FAIL_DARK_COLOR

.Pending
	background-color: $THEME_SECONDARY_DARK_COLOR

.Surveyed
	background-color: $THEME_PRIMARY_SOFT_COLOR

/* DYNAMIC CONTAINER - END */

// LABEL - END


// INPUT -START

/* GENERAL - START */

input, select, textarea
	color: $THEME_PRIMARY_DARK_BLACK


div.DivRadioButtonContainer, div.DivRadioButtonContainerVertical
	div.DivRadioButton
		background-color: transparent
		border-color: $THEME_TERTIARY_DARK_COLOR
		label
			background-color: $THEME_SECONDARY_DARK_COLOR

div.DivCheckBoxContainer
	div.DivCheckBox
		background-color: transparent
		border-color: $THEME_TERTIARY_DARK_COLOR
		label, span
			background-color: $THEME_SECONDARY_DARK_COLOR

div.DivRoundButtonContainer
	label, span
		color: $THEME_SECONDARY_DARK_COLOR !important

/* GENERAL - END */

/* THIRD PARTY - START */

.mat-mdc-form-field-appearance-fill .mat-mdc-form-field-flex, .mdc-text-field--filled, .mdc-text-field--focused, .mat-datepicker-toggle-active, .mat-form-field-appearance-outline:not(.mat-form-field-disabled), .mat-form-field-outline
	color: $THEME_TERTIARY_DARK_COLOR

.mat-form-field-flex
	&:hover
		background-color: transparet !important

.mat-mdc-form-field-appearance-fill .mat-mdc-form-field-flex, .mdc-text-field--filled
	background-color: $THEME_TERTIARY_SOFT_COLOR !important

.mdc-line-ripple
	border: none
	&::before
		border-bottom-style: none !important
	&::after
		border-bottom-style: none !important

.mat-mdc-icon-button, .mat-mdc-button-persistent-ripple
	&::before
		background-color: transparent !important

.mat-focused
	color: $THEME_TERTIARY_DARK_COLOR
	border: 1px solid $THEME_TERTIARY_NORMAL_COLOR

.ng-select
	background-image: url($URL_INPUT_RESOURCE + "input_dropdown.svg")
	background-repeat: no-repeat
	background-position: right
	background-size: $SPACE_HUGE

.ng-dropdown-panel
	background-color: $THEME_TERTIARY_SOFT_COLOR


div.DivFormMultipleDropdown, div.DivFormSingleDropdown
	.ng-select-container
		background-image: url($URL_INPUT_RESOURCE + "input_dropdown.svg")
		background-repeat: no-repeat
		background-position: right
		background-size: 36px
		background-color: $THEME_TERTIARY_LIGHT_COLOR
	.ng-dropdown-panel
		background-color: $THEME_TERTIARY_LIGHT_COLOR

div.DivFormMultipleDropdown
	.ng-value
		background-color: $THEME_TERTIARY_SOFT_COLOR
		.ng-value-icon.left
			border: 1px solid $THEME_TERTIARY_DARK_COLOR

// DROPDOWN AND FILTER - START

div.DivFormMultipleDropdown, div.DivFormSingleDropdown
	.ng-select-container
		background-image: url($URL_INPUT_RESOURCE + "input_dropdown.svg")
		background-repeat: no-repeat
		background-position: right
		background-size: 36px
		background-color: $THEME_TERTIARY_LIGHT_COLOR
	.ng-dropdown-panel
		background-color: $THEME_TERTIARY_LIGHT_COLOR

div.DivFormMultipleDropdown
	.ng-value
		background-color: $THEME_TERTIARY_SOFT_COLOR
		.ng-value-icon.left
			border: 1px solid $THEME_TERTIARY_DARK_COLOR

// DROPDOWN AND FILTER - END


/* THIRD PARTY - END */

/* NAVIGATION - START */

/* NAVIGATION - END */

/* SIGN IN - START */

/* SIGN IN - END */

// INPUT - END


// LINK - START

/* GENERAL - START */

a.ADownload
	color: $THEME_TERTIARY_SOFT_COLOR

form
	input[type=button]
		color: $THEME_TERTIARY_SOFT_COLOR
		+generateBackgroundGradientLeftRight($THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE, $THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE)
	input[type=button].ButtonPositive
		+generateBackgroundGradientLeftRight($THEME_INDICATOR_SUCCESS_NORMAL_COLOR, $THEME_INDICATOR_SUCCESS_NORMAL_RED, $THEME_INDICATOR_SUCCESS_NORMAL_GREEN, $THEME_INDICATOR_SUCCESS_NORMAL_BLUE, $THEME_INDICATOR_SUCCESS_DARK_COLOR, $THEME_INDICATOR_SUCCESS_DARK_RED, $THEME_INDICATOR_SUCCESS_DARK_GREEN, $THEME_INDICATOR_SUCCESS_DARK_BLUE)
	input[type=button].ButtonNegative
		+generateBackgroundGradientLeftRight($THEME_INDICATOR_FAIL_NORMAL_COLOR, $THEME_INDICATOR_FAIL_NORMAL_RED, $THEME_INDICATOR_FAIL_NORMAL_GREEN, $THEME_INDICATOR_FAIL_NORMAL_BLUE, $THEME_INDICATOR_FAIL_DARK_COLOR, $THEME_INDICATOR_FAIL_DARK_RED, $THEME_INDICATOR_FAIL_DARK_GREEN, $THEME_INDICATOR_FAIL_DARK_BLUE)

input[type=button].ButtonAttachment
	margin: 0px !important
	background-color: transparent
	background-image: url($URL_ICON_RESOURCE + "icon_attach.svg")

input[type=button].ButtonPDF
	background-image: url($URL_ICON_RESOURCE + "icon_file_pdf.svg")

input[type=button].ButtonExcel
	background-image: url($URL_ICON_RESOURCE + "icon_file_excel.svg")

input[type=button].ButtonCopy
	background-color: transparent
	background-image: url($URL_ICON_RESOURCE + "icon_copy.svg")

input[type=button].ButtonRemove
	background-image: url($URL_ICON_RESOURCE + "icon_remove.svg")

input[type=button].ButtonRemovefile
	background-image: url($URL_ICON_RESOURCE + "icon_remove.svg")

input[type=button].ButtonDownload
	background-image: url($URL_ICON_RESOURCE + "icon_download.svg")

input[type=button].ButtonAddTicket
	background-image: url($URL_ICON_RESOURCE + "icon_customer_care_white.svg")
	background-color: $THEME_SECONDARY_NORMAL_COLOR

input[type=button].ButtonShare
	background-image: url($URL_ICON_RESOURCE + "icon_share.svg")

input[type=button].ButtonAddPolicyRenewal
	background-image: url($URL_ICON_RESOURCE + "icon_search.svg")

input[type=button].ButtonAddPolicyOutstanding
	background-image: url($URL_ICON_RESOURCE + "icon_search.svg")

input[type=button].ButtonRemoveAttachment
	background-image: url($URL_ICON_RESOURCE + "icon_remove.png")

input[type=button]:disabled
	+generateBackgroundGradientLeftRight($THEME_TERTIARY_DARK_COLOR, $THEME_TERTIARY_DARK_RED, $THEME_TERTIARY_DARK_GREEN, $THEME_TERTIARY_DARK_BLUE, $THEME_TERTIARY_NORMAL_COLOR, $THEME_TERTIARY_NORMAL_RED, $THEME_TERTIARY_NORMAL_GREEN, $THEME_TERTIARY_NORMAL_BLUE)

/* GENERAL - END */

/* HOME - START */

input[type=button]#buttonNavigation
	background-color: transparent

/* HOME - END */

/* PRODUCT INFORMATION - START */

input[type=button]#buttonCalculator
	background-color: transparent
	background-image: url($URL_ICON_RESOURCE + "icon_calculate.svg")

/* PRODUCT INFORMATION - END */

/* TABLE - START */

div.DivTableControl, div.DivTableContainer, div.DivTableListControl
	div.DivButtonFirst.Enable, div.DivButtonPrevious.Enable, div.DivButton
		+generateBackgroundGradientLeftRight($THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE, $THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE)
	div.DivButtonNext.Enable, div.DivButtonLast.Enable
		+generateBackgroundGradientLeftRight($THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE, $THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE)
	div.DivButtonFirst.Disable, div.DivButtonPrevious.Disable, div.DivButtonFirst, div.DivButtonPrevious
		+generateBackgroundGradientLeftRight($THEME_TERTIARY_NORMAL_COLOR, $THEME_TERTIARY_NORMAL_RED, $THEME_TERTIARY_NORMAL_GREEN, $THEME_TERTIARY_NORMAL_BLUE, $THEME_TERTIARY_DARK_COLOR, $THEME_TERTIARY_DARK_RED, $THEME_TERTIARY_DARK_GREEN, $THEME_TERTIARY_DARK_BLUE)
	div.DivButtonNext.Disable, div.DivButtonLast.Disable, div.DivButtonNext, div.DivButtonLast
		+generateBackgroundGradientLeftRight($THEME_TERTIARY_DARK_COLOR, $THEME_TERTIARY_DARK_RED, $THEME_TERTIARY_DARK_GREEN, $THEME_TERTIARY_DARK_BLUE, $THEME_TERTIARY_NORMAL_COLOR, $THEME_TERTIARY_NORMAL_RED, $THEME_TERTIARY_NORMAL_GREEN, $THEME_TERTIARY_NORMAL_BLUE)
	div.DivButtonFirst input[type=button]
		background-image: url($URL_ICON_RESOURCE + "icon_first.svg")
	div.DivButtonPrevious input[type=button]
		background-image: url($URL_ICON_RESOURCE + "icon_previous.svg")
	div.DivButtonNext input[type=button]
		background-image: url($URL_ICON_RESOURCE + "icon_next.svg")
	div.DivButtonLast input[type=button]
		background-image: url($URL_ICON_RESOURCE + "icon_last.svg")
	div.ButtonForward:before, div.ButtonForward:hover:before, div.ButtonForwardInstall:before, div.ButtonForwardInstall:hover:before
		color: $THEME_TERTIARY_SOFT_COLOR
		background-color: $THEME_SECONDARY_DARK_COLOR
	input[type=button]
		background-color: transparent
	input[type=button].ButtonSurveiActive
		background-color: #4CAF50
	input[type=button].ButtonSurveiPasive
		background-color: $THEME_INDICATOR_FAIL_DARK_COLOR
	span.SpanTablePage
		color: $THEME_TERTIARY_SOFT_COLOR
	label
		color: $THEME_TERTIARY_SOFT_GREY_COLOR
	select
		+boxShadow(0, 4px, 8px, 0, rgba(0, 0, 0, 0.2))

table.TableBox
	+boxShadow(0px, 0px, 15px, 0px, rgba(0, 0, 0, 0.2))


/* TABLE - END */

// LINK - END


// CARD INSIDE GOOGLE MAPS FOR BRANCH MENU DETAIL START //

#divMainDashboard
	div.DivFormCardInside
		div.DivFormBranch
			input[type=button]
				background-color: $THEME_SECONDARY_DARK_COLOR
			div.DivBranchCardInformation
				img
					background-color: $THEME_SECONDARY_DARK_COLOR
			p
				color: $THEME_TERTIARY_SOFT_COLOR

			label
				color: $THEME_TERTIARY_DARK_COLOR


// CARD INSIDE GOOGLE MAPS FOR BRANCH MENU DETAIL END //

/* RENEWAL - START */

div.DivCardPolicy
	background-color: $THEME_TERTIARY_SOFT_COLOR
	span, h2
		color: $THEME_SECONDARY_NORMAL_COLOR

div.DivCardInformationPolicy
	+generateBackgroundGradientLeftRight($THEME_PRIMARY_LIGHT_COLOR, $THEME_PRIMARY_LIGHT_RED, $THEME_PRIMARY_LIGHT_GREEN, $THEME_PRIMARY_LIGHT_BLUE, $THEME_PRIMARY_DARK_COLOR, $THEME_PRIMARY_DARK_RED, $THEME_PRIMARY_DARK_GREEN, $THEME_PRIMARY_DARK_BLUE)

div.DivCardInformationPolicy
		span.spanStatusPolicyClosed
			background-color: $THEME_INDICATOR_SEVERITY_URGENT_COLOR
		span.spanStatusPolicyInforce
			background-color: $THEME_INDICATOR_SUCCESS_NORMAL_COLOR
		span.spanStatusPolicyTemporary
			background-color: $THEME_PRIMARY_HIGHLIGHT_COLOR
		span.spanStatusPolicyWaiting
			+generateBackgroundGradientLeftRight($THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE, $THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE)
		span.spanStatusExecutive
			+generateBackgroundGradientLeftRight($THEME_POLICY_EXECUTIVE_COLOR, $THEME_POLICY_EXECUTIVE_RED, $THEME_POLICY_EXECUTIVE_GREEN, $THEME_POLICY_EXECUTIVE_BLUE, $THEME_INDICATOR_SEVERITY_LOW_COLOR, $THEME_INDICATOR_SEVERITY_LOW_RED, $THEME_INDICATOR_SEVERITY_LOW_GREEN, $THEME_INDICATOR_SEVERITY_LOW_BLUE)

/* RENEWAL - STOP */


/* BRINS-WEBSITE - START */

div#divBodyHomeMenu
	select, option, a
		color: $THEME_PRIMARY_DARK_BLACK
	div.DivNavigationDetail
		ul
			li:hover
				a
					color: $THEME_SECONDARY_DARK_COLOR
			a:hover
				color: $THEME_SECONDARY_DARK_COLOR

div#divMainDashboard
	h1, h2, h3
		color: $THEME_TERTIARY_DARK_BLUE_COLOR
	h4
		color: $THEME_SECONDARY_DARK_COLOR
	p
		color: $THEME_QUATERNARY_DARK_COLOR
	p#paragraphName
		color: $THEME_PRIMARY_DARK_BLACK
	ol.OrderedNumber
		li
			color: $THEME_QUATERNARY_DARK_COLOR
	div.DivContentPartners
		h2, p, span
			color: $THEME_TERTIARY_SOFT_COLOR
	ol.OrderedSoft
		li
			color: $THEME_TERTIARY_SOFT_COLOR


div.DivConversationMelisa
	div.DivBannerMelisa
		+backgroundGradientLeftRight($THEME_TERTIARY_DARK_ORANGE_COLOR,$THEME_TERTIARY_SOFT_ORANGE_COLOR)
		div.DivBanner
			p
				color: $THEME_TERTIARY_SOFT_COLOR
	div.DivConversation
		background-color: $THEME_TERTIARY_SOFT_COLOR
		div.DivConversationHeader
			+backgroundGradientLeftRight($THEME_TERTIARY_DARK_ORANGE_COLOR,$THEME_TERTIARY_SOFT_ORANGE_COLOR)
			label
				color: $THEME_TERTIARY_SOFT_COLOR
		div.DivConversationDetail
			background-color: #e8e8e8
			div.DivConversationMessage
				background-color: $THEME_TERTIARY_SOFT_COLOR
	div.DivConversationSendMessage
		textarea
			border: 2px solid #91B9CA
			background-color: $THEME_TERTIARY_SOFT_COLOR
		input[type=image]#imageAttach
			background-color: $THEME_TERTIARY_SOFT_COLOR
		input[type=image]#imageSend
			background-color: $THEME_TERTIARY_SOFT_COLOR
			+backgroundGradientLeftRight($THEME_TERTIARY_SOFT_BLUE_COLOR, $THEME_TERTIARY_DARK_BLUE_COLOR)

div.DivBodySiteContainer
	div.DivContentAwardsDetail
		background: $THEME_TERTIARY_SOFT_COLOR
		label
			background-color: #CDDCE7
			color: #064F89
		a.ButtonForward
			color: $THEME_TERTIARY_DARK_ORANGE_COLOR
			svg
				stroke: $THEME_TERTIARY_DARK_ORANGE_COLOR
		h5
			color: $THEME_TERTIARY_SOFT_BLUE_COLOR

div.DivContentNewsHorizontal, div.DivContentNewsVertical
	div.DivContentNewsDetail
		background: $THEME_TERTIARY_SOFT_COLOR
		label
			background-color: #CDDCE7
			color: #064F89
		a.ButtonForward
			color: $THEME_TERTIARY_DARK_ORANGE_COLOR
			svg
				stroke: $THEME_TERTIARY_DARK_ORANGE_COLOR
		h3, p.ParagraphBold
			color: $THEME_PRIMARY_DARK_BLACK !important


div#divBodyDashboardAboutBRINS
	h1, p
		color: $THEME_TERTIARY_SOFT_COLOR
	span
		color: $THEME_TERTIARY_SOFT_COLOR

div#divBodyDashboardAboutBRINS, div.DivContentPartners
	+backgroundGradientLeftRight($THEME_TERTIARY_SOFT_BLUE_COLOR, $THEME_TERTIARY_DARK_BLUE_COLOR)

.ButtonForwardLight:before, .ButtonForwardLight:hover:before
	color: $THEME_TERTIARY_SOFT_COLOR
	background-color: $THEME_SECONDARY_DARK_COLOR !important
	span
		color: $THEME_TERTIARY_SOFT_COLOR

div#divBodyAbout, div#divBodyEditProfile, div#divBodyNotification
	div.DivContentMain
		background-color: $THEME_TERTIARY_SOFT_COLOR
		h1:first-of-type
			color: $THEME_TERTIARY_DARK_BLUE_COLOR
		h1:last-of-type
			color: $THEME_BRIINSURANCE_ORANGE_COLOR
		select
			color: $THEME_PRIMARY_DARK_BLACK
		label, option
			color: $THEME_TERTIARY_SOFT_GREY_COLOR
	div.DivContentDescription
		h1
			color: $THEME_TERTIARY_DARK_BLUE_COLOR
		p
			color: $THEME_PRIMARY_DARK_BLACK
	div#divContentWhatBRINS
		background-color: #E3E6F0
		p
			color: #808080
div#divBodyEditProfile, div#divBodyNotification
	div.DivContentMain
		background-color: $THEME_QUARTERNARY_SOFT_COLOR


div#divBodyMissionAndVission
	h1
		color: $THEME_TERTIARY_DARK_BLUE_COLOR
	p
		color: $THEME_SECONDARY_DARK_COLOR
	div.DivContentDescription
		div.DivContentVisionDescription
			+backgroundGradientLeftRight($THEME_TERTIARY_SOFT_BLUE_COLOR, $THEME_TERTIARY_DARK_BLUE_COLOR)
		div.DivContentMissionDescription
			+backgroundGradientLeftRight($THEME_TERTIARY_DARK_ORANGE_COLOR, $THEME_TERTIARY_SOFT_ORANGE_COLOR)
		div.DivContentVisionDescriptionParagraph, div.DivContentMissionDescriptionParagraph
			p
				color: $THEME_TERTIARY_SOFT_COLOR
	div.DivContentDescription
		h1
			color: $THEME_TERTIARY_SOFT_COLOR

div#divBodyPartnerAndClient, div#divBodyStructureOrganization
	div.DivContentMain
		h1
			color: $THEME_TERTIARY_DARK_BLUE_COLOR
	div.DivContentDescription
		h1, h3
			color: $THEME_TERTIARY_DARK_BLUE_COLOR
		h4
			color: $THEME_SECONDARY_DARK_COLOR
		p
			color: $THEME_TERTIARY_SOFT_GREY_COLOR
		label
			color: $THEME_TERTIARY_DARK_BLUE_COLOR

div#divBodyTabAbout, div#divTabContainer
	div.DivContentTabAbout
		background-color: $THEME_QUATERNARY_LIGHT_COLOR
		button
			background-color: $THEME_QUATERNARY_LIGHT_COLOR
			color: $THEME_TERTIARY_DARK_BLUE_COLOR
		button:hover, button:active, button:focus, button.ButtonActive
			background-color: $THEME_SECONDARY_DARK_COLOR
			color: $THEME_TERTIARY_SOFT_COLOR

div#divMainDashboard
	div.DivBodyGeneral
		div.DivContentCorporateTitle
			color: $THEME_TERTIARY_DARK_BLUE_COLOR
		div.DivContentCorporatePage
			h1
				color: #e9e9e9
		div.DivContentCorporate
			div.DivContentDescription
				h1
					color: $THEME_TERTIARY_DARK_BLUE_COLOR
				p
					color: #978f8f
					span
						color: $THEME_CONVERSATION_PRIVATE_COLOR
	div#divBodyContentOrdered
		div.DivContentOrderedListDescription
			h1
				color: $THEME_SECONDARY_DARK_COLOR
			h2
				color: $THEME_PRIMARY_DARK_BLACK
			h2.headingFirstLetterBig:first-letter
				color: $THEME_SECONDARY_DARK_COLOR

div#divBodyFooterContainer
	div.DivFooterInfoPage
		div.DivFooterVerticalContainer, div.DivFooterHorizontalContainer
			label
				color: #D36707
		p, a
			color: $THEME_QUATERNARY_DARK_COLOR
		div.DivFooterCopyright
			p
				color: $THEME_TERTIARY_DARK_BLUE_COLOR
			hr
				border: 1px solid $THEME_TERTIARY_DARK_BLUE_COLOR
div#divBodyInformation
	div.DivContentMain, div.DivContentDescription
		h1, h4
			color: $THEME_TERTIARY_DARK_BLUE_COLOR
		p
			color: $THEME_QUATERNARY_DARK_COLOR

div#divContentTab
	background-color: $THEME_QUATERNARY_LIGHT_COLOR
	button
		background-color: $THEME_QUATERNARY_LIGHT_COLOR
		color: $THEME_TERTIARY_DARK_BLUE_COLOR
	button:hover, button:active, button:focus, button.ButtonActive
		background-color: $THEME_INDICATOR_SUCCESS_NORMAL_COLOR
		color: $THEME_TERTIARY_SOFT_COLOR

div.DivBodyContainerDark
	background-color: $THEME_QUATERNARY_LIGHT_COLOR

div#divBodyStructureOrganization
	div.DivContentDescription
		border-bottom: 2px solid $THEME_QUATERNARY_DARK_COLOR
div.DivContentDownload
	a
		color: $THEME_TERTIARY_SOFT_BLUE_COLOR

div#divContentJoinUsCareers
	p
		color: $THEME_PRIMARY_DARK_BLACK

div#divContentJoinUsPartnership
	h2, p
		color: $THEME_TERTIARY_SOFT_COLOR

.ButtonSlideDetail
	+backgroundGradientLeftRight($THEME_TERTIARY_SOFT_BLUE_COLOR, $THEME_TERTIARY_DARK_BLUE_COLOR)

div.DivContentButtonDetail
	.ButtonForward
		width: 200px
		&:before
			background-color: $THEME_TERTIARY_DARK_BLUE_COLOR
		.ButtonContent
			color: $THEME_TERTIARY_SKY_BLUE_COLOR
			&:after
				content: "Selengkapnya"
				text-align: center
				margin: auto
				color: $THEME_TERTIARY_SOFT_COLOR
				mix-blend-mode: difference
		.ButtonContentSubmit
			color: $THEME_TERTIARY_SKY_BLUE_COLOR
			&:after
				content: "Submit"
				text-align: center
				margin: auto
				color: $THEME_TERTIARY_SOFT_COLOR
				mix-blend-mode: difference
		.ButtonContentInsertBRINSEditorial
			color: $THEME_TERTIARY_SKY_BLUE_COLOR
			&:after
				content: "Kirim Artikel"
				text-align: center
				margin: auto
				color: $THEME_TERTIARY_SOFT_COLOR
				mix-blend-mode: difference
		.ButtonContentBRINSEditorial
			color: $THEME_TERTIARY_SKY_BLUE_COLOR
			&:after
				content: "Show Outlook"
				text-align: center
				margin: auto
				color: $THEME_TERTIARY_SOFT_COLOR
				mix-blend-mode: difference
		.ButtonContentAll
			&:after
				content: "Semua"
		.ButtonContentSoft
			&:after
				color: $THEME_TERTIARY_SOFT_COLOR
	.ButtonForwardSubmit
		width: 135px
	.ButtonAll
		width: 150px
	.ButtonSubmit
		width: 120px !important
		margin: 0px auto !important
	.ButtonForwardInstall
		width: 85px
		margin-right: 5px
		&:before
			background-color: $THEME_TERTIARY_DARK_BLUE_COLOR
		.ButtonContent
			color: $THEME_TERTIARY_SKY_BLUE_COLOR
			&:after
				content: "Install"
				text-align: center
				margin: auto
				color: $THEME_TERTIARY_SOFT_COLOR
				mix-blend-mode: difference
	.ButtonForwardTable
		span
			color: $THEME_TERTIARY_SKY_BLUE_COLOR
	a.ButtonForwardTable:before
		background-color: $THEME_TERTIARY_DARK_BLUE_COLOR
	a.ButtonForwardTable:hover:before
		background-color: $THEME_TERTIARY_DARK_BLUE_COLOR

div#divMainDashboard
	div.DivContentCalenderDetail
		div.DivContentCalenderDate
			h3
				color: $THEME_SECONDARY_DARK_COLOR
		div.DivContentCalenderDescription
			p
				color: $THEME_TERTIARY_DARK_BLUE_COLOR

div#divTicketHistory
	form
		div.DivForm
			background-image: url($URL_SHAPE_RESOURCE + "shape_ticket.svg")
			background-size: 100%
			background-repeat: no-repeat

//START - CUSTOMER CARE

div#divContentTicketAndCallCenter
	div.DivContent
		background-color: #E3E6F0
	div.DivContentDescription
		div.DivContentDetail
			p
				color: $THEME_TERTIARY_DARK_BLUE_COLOR
				font-weight: 600
div#divContentPartnerWorkshop
	background-color: $THEME_TERTIARY_SOFT_COLOR

div#divContainerPartnershipWorkshop
		div.DivContent2Column
			div.DivContentInfo
				div.DivFormHorizontalContainer
					a.AnchorContactDetail
						color: $THEME_PRIMARY_DARK_BLACK

//END - CUSTOMER CARE


// START - SOCIAL VALUE


div#divMainDashboard
	div#divBodySocialValueCEO
		div.DivContent2Column
			div.DivContent
				label
					color: $THEME_TERTIARY_DARK_ORANGE_COLOR
				p
					color: $THEME_PRIMARY_DARK_BLACK
div#divBodySocialValueInformation
	div.DivContentMain
		div.DivContentBoxDetailShow
			+backgroundGradientLeftRight($THEME_TERTIARY_SOFT_BLUE_COLOR, $THEME_TERTIARY_DARK_BLUE_COLOR)
			div.DivContentBoxDescription
				h3
					color: $THEME_TERTIARY_SOFT_COLOR

form
	div.DivForm
		fieldset
			label
				color: $THEME_TERTIARY_NORMAL_COLOR
			input, inputarea, select, textarea
				background-color: $THEME_TERTIARY_SOFT_COLOR
				border: 1px solid $THEME_TERTIARY_NORMAL_COLOR
			select
				background-image: url($URL_ICON_RESOURCE + "icon_dropdown.svg")
		input.inputAttachment
			background: #14A659


// END - SOCIAL VALUE


// START - SEARCH

div#divMainDashboard
	div.DivContentSearchResult
		ul
			li:hover
				color: $THEME_SECONDARY_DARK_COLOR
	div.DivContentSearchContainer
		div.DivContentNotFound
			h2
				color: $THEME_QUATERNARY_DARK_COLOR

// END - SEARCH


// START - BRINSUPDATE


div#divMainBRINSUpdate
	div#divFinancialLiteracy
		div.DivContent2Column
			div.DivContent:nth-child(1)
				+backgroundGradientLeftRight($THEME_TERTIARY_SOFT_BLUE_COLOR, $THEME_TERTIARY_DARK_BLUE_COLOR)
			div.DivFinancialLiteracy
				h1
					margin: 0px
				h2, p, span
					color: $THEME_TERTIARY_SOFT_COLOR
div#divMainBRINSUpdateArticle
	label
		a
			color: $THEME_TERTIARY_DARK_BLUE_COLOR
		a.ADataSourceArticleEditorial
			color: $THEME_TERTIARY_SOFT_ORANGE_COLOR

div.DivContentButtonMore
	a
		color: $THEME_TERTIARY_DARK_ORANGE_COLOR
		border: solid 5px $THEME_TERTIARY_SOFT_ORANGE_COLOR

div.DivClassButtonPlayVideo
	p
		color: $THEME_TERTIARY_SOFT_COLOR !important


// END - BRINS UPDATE


// START - PRIVACY

div.DivHeaderPolicy
	h1
		color: $THEME_TERTIARY_SOFT_COLOR !important
div.DivButton2Column
	button
		color: $THEME_PRIMARY_DARK_BLACK
		background-color: $THEME_TERTIARY_SOFT_COLOR
	button:hover, button:active, button:focus, button.ButtonActive
		background-color: $THEME_SECONDARY_DARK_COLOR
		color: $THEME_TERTIARY_SOFT_COLOR

// END - PRIVACY


// START CUSTOMER CARE

div#divMainCustomerCareSubmissionClaim
	div#divBodyTabAbout
		div.DivContentTabAbout
			button:hover, button:active, button:focus
				background-color: $THEME_QUATERNARY_LIGHT_COLOR !important
				color: $THEME_TERTIARY_DARK_BLUE_COLOR !important
	div.DivHeaderSubmissionClaim
		div.DivContentNewsHorizontal
			background-color: $THEME_TERTIARY_SOFT_COLOR

button.ButtonLight
	+backgroundGradientLeftRight($THEME_TERTIARY_DARK_ORANGE_COLOR,$THEME_TERTIARY_SOFT_ORANGE_COLOR)
	color: $THEME_TERTIARY_SOFT_COLOR
button.ButtonDark
	+backgroundGradientLeftRight($THEME_TERTIARY_SOFT_BLUE_COLOR, $THEME_TERTIARY_DARK_BLUE_COLOR)
	color: $THEME_TERTIARY_SOFT_COLOR

div.DivBRINSUpdateArticleDetail
	p, li
		color: $THEME_QUATERNARY_DARK_COLOR !important

// END - CUSTOMER CARE

/* BRINS-WEBSITE - STOP */


// PNM DOWNLOAD - START

div#divHeaderDownload
	header#headerDownload
		h1, span
			color: $THEME_SECONDARY_DARK_COLOR
	form
		div.DivForm
			h4, h5
				color: #F26F21

// PNM DOWNLOAD - END