/* @charset "utf-8"; */
/* CSS Document */

// IMPORT - START

@import form-initialization-general

// IMPORT - END


// LABEL - START

/* GENERAL - START */

form
	div
		h3
			font-family: $URL_FAMILYTERTIARY_SECONDARY
		fieldset
			legend
				font-family: $URL_FAMILYTERTIARY_PRIMARY
			label
				font-family: $URL_FAMILYTERTIARY_PRIMARY
			label.LabelPreffix,	label.LabelSuffix
				font-family: $URL_FAMILYTERTIARY_SECONDARY

div.DivBodyGeneral, div.DivVerifierGeneral, div.DivCenterGeneral
	header.HeaderGeneral
		h2
			font-family: $URL_FAMILYTERTIARY_TERTIARY
		p
			font-family: $URL_FAMILYTERTIARY_PRIMARY
	div.DivForm
		h3, h4
			font-family: $URL_FAMILYTERTIARY_SECONDARY
		label, .legend-title
			font-family: $URL_FAMILYTERTIARY_PRIMARY
		span, p
			font-family: $URL_FAMILYTERTIARY_SECONDARY
		textarea#spanIncidentNote
			font-family: $URL_FAMILYTERTIARY_SECONDARY
		textarea.TextareaDescriptionHeader, textarea.TextareaConversationDisable
			font-family: $URL_FAMILYTERTIARY_PRIMARY
	div.DivCenterContainer
		div.DivForm
			div.DivSubHeaderHeadline
				label, h2
					font-family: $URL_FAMILYTERTIARY_SECONDARY
			div.DivHeaderStatus
				span
					font-family: $URL_FAMILYTERTIARY_PRIMARY
			div.DivFormHorizontalContainer
				div.DivHeaderInfo
					dl
						dt
							font-family: $URL_FAMILYTERTIARY_PRIMARY
						dd
							font-family: $URL_FAMILYTERTIARY_SECONDARY
				div.DivHeaderList
					ul
						li
							span
								font-family: $URL_FAMILYTERTIARY_PRIMARY
div.DivVerifierGeneral
	div.DivForm
		h3
			font-family: $URL_FAMILYTERTIARY_TERTIARY

div.DivProfileGeneral
	div.DivProfileInfo
		div.DivProfileInfoDetails
			span
				font-family: $URL_FAMILYTERTIARY_SECONDARY

div.DivRoundButtonContainer
	label
		font-family: $URL_FAMILYTERTIARY_SECONDARY !important

/* GENERAL - END */

/* NAVIGATION - START */

nav, div#divNavigationAside
	figure
		span#spanProfileNameInitial
			font-family: $URL_FAMILYSECONDARY_PRIMARY
		figcaption
			span#spanProfileName
				font-family: $URL_FAMILYTERTIARY_SECONDARY
			span#spanProfilePosition
				font-family: $URL_FAMILYTERTIARY_PRIMARY
	ul
		li
			font-family: $URL_FAMILYTERTIARY_PRIMARY
	a
		font-family: $URL_FAMILYTERTIARY_PRIMARY
	div
		a
			font-family: $URL_FAMILYTERTIARY_PRIMARY

/* NAVIGATION - END */

/* SIGN IN - START */

div#divBodySignIn
	header#headerSignIn
		h1
			font-family: $URL_FAMILYSECONDARY_PRIMARY
		span
			font-family: $URL_FAMILYTERTIARY_PRIMARY
	form
		div.DivNotRegistered
			font-family: $URL_FAMILYTERTIARY_PRIMARY

/* SIGN IN - END */

/* DASHBOARD - START */

div#divMainDashboard
	div.DivHighlight
		span.SpanHighlight
			font-family: $URL_FAMILYTERTIARY_SECONDARY

form div.DivForm fieldset, div.DivForm
	div.DivFormHorizontalContainer, div.DivFormVerticalContainer, div.DivFormListHorizontalContainer
		div.DivListChart
			font-family: $URL_FAMILYTERTIARY_SECONDARY

/* DASHBOARD - END */

/* TABLE - START */

div.DivTableContainer, div.DivTableListContainer, div.DivTableListContainerLeft
	span.SpanTablePage
		font-family: $URL_FAMILYTERTIARY_PRIMARY
	ul.ListTableThumbnails
		figure
			figcaption
				font-family: $URL_FAMILYTERTIARY_PRIMARY
	ul.ListTableTicket, ul.ListTableConversation
		span.SpanTicketNumber, span.SpanConversationDate
			font-family: $URL_FAMILYTERTIARY_PRIMARY
		span.SpanTicketTitle, span.SpanConversationTitle
			font-family: $URL_FAMILYTERTIARY_SECONDARY
		dl
			dt
				font-family: $URL_FAMILYTERTIARY_PRIMARY
			dd
				font-family: $URL_FAMILYTERTIARY_SECONDARY
		li
			div.DivConversationProfile
				span.SpanChatProfileNameInitial
					font-family: $URL_FAMILYSECONDARY_PRIMARY

div.DivTableContainer
	th, td
		font-family: $URL_FAMILYTERTIARY_PRIMARY


/* TABLE - END */

/* LOADING - START */

div#divCurtainLoading, div#divCurtainGeneral, div#divModal
	span#spanLoadingPercentage
		font-family: $URL_FAMILYPRIMARY_SECONDARY
	span
		font-family: $URL_FAMILYPRIMARY_PRIMARY
div#divModal
	span
		text-align: center
		font-weight: bold
		font-size: 1.3em
	label
		text-align: center
		font-size: 1em
		color: #666666
		margin: 10px 0px
		font-family: $URL_FAMILYTERTIARY_PRIMARY

/* LOADING - END */

// LABEL - END


// INPUT - START

/* GENERAL - START */

form
	div
		fieldset
			input
				font-family: $URL_FAMILYTERTIARY_PRIMARY
			div.DivConversationHeader
				span.SpanConversationDate
					font-family: $URL_FAMILYTERTIARY_PRIMARY
				span.SpanConversationTitle
					font-family: $URL_FAMILYTERTIARY_SECONDARY

/* GENERAL - END */

// INPUT - END


/* THIRD PARTY */

// DROPDOWN MULTIPLE -START

div.DivForm
	div.DivFormMultipleDropdown,div.DivFormSingleDropdown
		span, p
				font-family: $URL_FAMILYTERTIARY_PRIMARY

// DORPDOWN MULTIPLE -END

// MONITORING RENEWAL - START

div.DivCardPolicy
	span, h2
		font-family: $URL_FAMILYTERTIARY_SECONDARY

div.DivPolicyAttributes
	span
		font-family: $URL_FAMILYTERTIARY_PRIMARY

div.DivCardInformationPolicy
	div.DivPolicyInfo
		span
			font-family: $URL_FAMILYTERTIARY_SECONDARY

// MONITORING RENEWAl - END


// BRINS WEBSITE - START

div#divBodyHomeMenu
	select, option
		font-family: $URL_FAMILYTERTIARY_PRIMARY

body, div.DivBodyContainer
	font-family: $URL_FAMILYTERTIARY_TERTIARY
	h1, h2, h3, h4, span
		font-family: $URL_FAMILYTERTIARY_SECONDARY
	p, label, a, input, select, textarea, label span
		font-family: $URL_FAMILYTERTIARY_PRIMARY

div#divBodyHomeMenu
	nav
		ul
			li
				a
					font-family: $URL_FAMILYTERTIARY_PRIMARY

div.DivBodyGeneral
	font-family: $URL_FAMILYTERTIARY_SECONDARY
	div
		font-family: $URL_FAMILYTERTIARY_PRIMARY
	div.DivContentCorporatePage
		font-family: $URL_FAMILYTERTIARY_SECONDARY

div#divMainDashboard
	select, option
		font-family: $URL_FAMILYTERTIARY_PRIMARY
	div#divBodyFooterContainer
		p, a, input
			font-family: $URL_FAMILYTERTIARY_PRIMARY
		label
			font-family: $URL_FAMILYTERTIARY_TERTIARY
	div#divBodyDashboardAboutBRINS
		div.DivContentCorporate
			h1
				font-family: $URL_FAMILYTERTIARY_PRIMARY
			div.DivContentCorporateDescription
				p
					font-family: $URL_FAMILYTERTIARY_SECONDARY
	div#divBodyDashboardBRINSUpdate
		div.DivContentDescription
			p
				span
					font-family: $URL_FAMILYTERTIARY_PRIMARY
	div#divBodyDashboardBRIGroup
		h2
			font-family: $URL_FAMILYTERTIARY_PRIMARY
	div#divBodyDashboardAboutBRINS
		h1
			font-family: $URL_FAMILYTERTIARY_PRIMARY
		p
			font-family: $URL_FAMILYTERTIARY_SECONDARY
	div.DivContentAwardsDetail
		label
			font-family: $URL_FAMILYTERTIARY_TERTIARY
		a.ButtonForward
			span
				font-family: $URL_FAMILYTERTIARY_TERTIARY
		h5
			font-family: $URL_FAMILYTERTIARY_PRIMARY

	div#divBodyInformation, div#divBodyPartnerAndClient, div#divBodyTabAbout
		h1
			font-family: $URL_FAMILYTERTIARY_SECONDARY
		p
			font-family: $URL_FAMILYTERTIARY_PRIMARY
	div#divBodyStructureOrganization
		h1, h3
			font-family: $URL_FAMILYTERTIARY_SECONDARY
		p
			font-family: $URL_FAMILYTERTIARY_PRIMARY
			span
				font-family: $URL_FAMILYTERTIARY_SECONDARY
	div#DivBodyDashboardAward
		h2
			font-family: $URL_FAMILYTERTIARY_PRIMARY
	div#divBodySocialValueInformation
		p.ParagraphBold
			font-family: $URL_FAMILYTERTIARY_SECONDARY

div#divBodyDashboardPartners
	h2
		font-family: $URL_FAMILYTERTIARY_TERTIARY

div#divBodyAbout
	h1
		font-family: $URL_FAMILYTERTIARY_SECONDARY
	p
		font-family: $URL_FAMILYTERTIARY_PRIMARY
	div#divContentWhatBRINS
		h1
			font-family: $URL_FAMILYTERTIARY_TERTIARY

div#divBodyMissionAndVission
	div.DivContentDescription
		label
			font-family: $URL_FAMILYTERTIARY_TERTIARY
		div.DivContentVisionDescriptionDetail, div.DivContentMissionDescriptionDetail
			h1
				font-family: $URL_FAMILYTERTIARY_TERTIARY

div#divBodyTabAbout
	div.DivContentTabAbout
		button
			font-family: $URL_FAMILYTERTIARY_PRIMARY

div#divContentTab
	button
		font-family: $URL_FAMILYTERTIARY_PRIMARY

div.DivContentButtonDetail
	div.ButtonForward, div.ButtonForwardTable
		span
			font-family: $URL_FAMILYTERTIARY_TERTIARY

div#divMainBRINSUpdateArticle
	div.DivBRINSUpdateArticleDetail
		strong
			font-family: $URL_FAMILYTERTIARY_SECONDARY

div.DivContentNewsDetail
	label
		font-family: $URL_FAMILYTERTIARY_SECONDARY
	a
		span
			font-family: $URL_FAMILYTERTIARY_PRIMARY
	p.ParagraphBold
		font-family: $URL_FAMILYTERTIARY_SECONDARY

button.ButtonSubmit
	font-family: $URL_FAMILYTERTIARY_PRIMARY


div.DivBRINSUpdateArticleDetail
	p, li
		font-family: $URL_FAMILYTERTIARY_PRIMARY

// BRINS WEBSITE - END


// PNM Download - START

div#divHeaderDownload
	header#headerDownload
		h1
			font-family: $URL_FAMILYSECONDARY_PRIMARY
		span
			font-family: $URL_FAMILYTERTIARY_PRIMARY
	form
		div.DivNotRegistered
			font-family: $URL_FAMILYTERTIARY_PRIMARY

// PNM Download - END